import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import tw from "tailwind.macro"
import { Parallax } from "react-spring/renderprops-addons.cjs"

// Components
import Layout from "../components/LayoutIndex"
import ProjectCard from "../components/ProjectCard"
import Button from "../components/button"

// Elements
import Inner from "../elements/Inner"
import { Title, BigTitle, Subtitle } from "../elements/Titles"

// Views
import Hero from "../views/Hero"
import Projects from "../views/Projects"
import About from "../views/About"
import Contact from "../views/Contact"

import avatar from "../images/avatar.jpg"

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`

const AboutSub = styled.span`
  ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`

const Index = () => (
  <>
    <Layout />
    <Parallax pages={5}>
      <Hero offset={0}>
        <BigTitle>
          Hey there, <br /> I'm Nurul.
        </BigTitle>
        <Subtitle>
          I'm a Software Engineer / Web Developer. I help people make the world
          better through quality software. I am an avid learner and try to
          explain things with words and code. <br />
          You can have a look at my blogs.
          <Link to="/blog/">
            <Button marginTop="35px">Go to Blog</Button>
          </Link>
        </Subtitle>
      </Hero>
      <Projects offset={1}>
        <Title>My Info</Title>
        <ProjectsWrapper>
          <ProjectCard
            title="My Education"
            bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
          >
            I did my Bachelors from L.D College of Engineering, Ahmedabad in
            Information Technology. And my schooling was done in Ankur High
            School - Ahmedabad
          </ProjectCard>
          <ProjectCard
            title="My Tech Stack"
            bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
          >
            - HTML / CSS / JavaScript [React and Node]
            <br />
            - AWS / GCP / Firebase / MongoDB <br />- Docker / GraphQL
          </ProjectCard>
          <ProjectCard
            title="My Work Profile"
            bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)"
          >
            I am working as an Engineer at{" "}
            <a href="https://prisma.io" style={{ color: "#fff" }}>
              Prisma.
            </a>
            <br />
            Prisma helps app developers build faster and make fewer errors with
            an open source database toolkit for PostgreSQL, MySQL, SQL Server,
            SQLite, MongoDB and CockroachDB
          </ProjectCard>
          <ProjectCard
            title="My Posts and Videos"
            bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
          >
            You can have a look at articles and videos i make about tech
            <br />
            <a href="https://dev.to/nurul3101" style={{ color: "#fff" }}>
              - dev.to
            </a>
            <br />
            <a
              href="https://www.youtube.com/channel/UCkY4S2zMhIWaBKohgttuS2g/featured?view_as=subscriber"
              style={{ color: "#fff" }}
            >
              - youtube
            </a>
            <br />
            <a href="https://hashnode.com/@nurul1131" style={{ color: "#fff" }}>
              - hashnode
            </a>
            <br />
          </ProjectCard>
        </ProjectsWrapper>
      </Projects>
      <About offset={3}>
        <Title>About</Title>
        <AboutHero>
          <Avatar src={avatar} alt="Nurul Sundarani" />
          <AboutSub>
            I'm a software engineer. I was born in 1996 (you can do the math)
            and grew up in Ahmedabad, India. I love playing cricket, reading
            novels and creating cool stuff.
          </AboutSub>
        </AboutHero>
        <AboutDesc>
          You can know about my day to day life by following me on any / all of
          these. <br />
          <a href="https://twitter.com/NurulSundarani">Twitter</a>{" "}
          <a href="https://www.instagram.com/nurul_1131/">Instagram</a>{" "}
          <a href="https://www.facebook.com/nurulraza.sundarani">Facebook</a>{" "}
          <a href="https://www.linkedin.com/in/nurul-sundarani-676b1711b/">
            LinkedIn
          </a>{" "}
        </AboutDesc>
      </About>
      <Contact offset={4}>
        <Inner>
          <Title>Thanks!</Title>
          <ContactText>
            If you have any suggestions or wanna just say{" "}
            <a href="mailto:nurulraza26@gmail.com">Hi</a> <br />
            My Email is <i>nurul@nurulsundarani.me</i>
            <br />
            Also you can find all of my open source projects here at{" "}
            <a href="https://github.com/nurul3101">Github</a>
          </ContactText>
        </Inner>
        <Footer>
          &copy; Made by <a href="https://nurulsundarani.me">Nurul Sundarani</a>
          . {new Date().getFullYear()}{" "}
        </Footer>
      </Contact>
    </Parallax>
  </>
)

export default Index
