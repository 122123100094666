const tailwind = require('../tailwind');

module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Nurul Sundarani - Software Engineer', // Navigation and Site Title
  siteTitleAlt: 'Nurul', // Alternative Site title for SEO
  siteTitleShort: 'Nurul', // short_name for manifest
  siteHeadline: 'Nurul - Web Developer / Software Engineer', // Headline for schema.org JSONLD
  siteUrl: 'https://nurulsundarani.me', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logo.png', // Used for SEO and manifest
  siteDescription: 'Nurul Sundarani - Software Engineer Portfolio',
  author: 'Nurul Sundarani', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@nurulsundarani', // Twitter Username
  ogSiteName: 'nurul', // Facebook Site Name
  ogLanguage: 'en_US', // Facebook Language
  googleAnalyticsID: 'UA-135127742-1',

  // Manifest and Progress color
  themeColor: tailwind.colors.orange,
  backgroundColor: tailwind.colors.blue
};
